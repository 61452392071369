import React from 'react';
import queryString from 'query-string';
import styled from 'styled-components';
import { Link } from 'gatsby';

import SEO from '../../components/SEO';
import Body from '../../components/layout/Body';
import ResponsiveContainer from '../../components/layout/ResponsiveContainer';
import ContentWrapper from '../../components/layout/ContentWrapper';

import ContactForm from '../../components/forms/ContactForm';

import { ViewportBreakpoints } from '../../components/cssConstants';

import H1 from '@rotaready/frecl/build/H1';
import H2 from '@rotaready/frecl/build/H2';
import Text from '@rotaready/frecl/build/Text';
import UL from '@rotaready/frecl/build/UL';
import TextListItem from '@rotaready/frecl/build/TextListItem';

import ClientLogoBar, { ClientLogos } from '../../components/section/ClientLogoBar';
import Blockquote from '../../components/frills/Blockquote';

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 60px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    margin-bottom: 100px;
  }
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex-direction: row;
  }
`;

const BodyItem = styled.div`
  flex: 1 1 auto;
  margin-top: 40px;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    flex: 1 1 50%;
    margin: 0;
  }
`;

const Section = styled.div`
  margin: 0 0 40px 0;

  @media (min-width: ${ViewportBreakpoints.tabletPortraitUpper}) {
    padding-right: 120px;
  }
`;

const Heading = styled(H2).attrs({
  uistyle: 'brand160',
})``;

const TextBlock = styled.div`
  margin-top: 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const LinkSection = styled.div`
  margin: 0 0 40px 0;
`;

const LinkPadding = styled.div`
  margin-top: 20px;
`;

const header = {
  backgroundColor: 'white',
  invertColors: false,
};

class DemoPage extends React.Component {
  render({ location }) {
    const {
      given_name: givenName,
      family_name: familyName,
      email,
    } = location.search ? queryString.parse(location.search) : {};

    return (
      <Body header={header}>
        <SEO
          title="Demo Request - Rotaready"
          description="See Rotaready's cutting edge staff scheduling software in action with a live demo."
          url="demo-brand"
        />

        <ResponsiveContainer>
          <ContentWrapper>
            <TitleWrapper>
              <H1 uistyle="brand160" text="Request a demo" />
            </TitleWrapper>

            <BodyWrapper>
              <BodyItem>
                <Section>
                  <Heading text="Seriously good rota software" />

                  <TextBlock>
                    <Text size="lg">
                      Whether you're a single or multi-site operator, Rotaready takes the stress out of staff scheduling and enables you to build rotas that are perfectly aligned with demand. Effortlessly schedule rotas, optimise wage spend, record attendance and approve timesheets for <Link style="color:inherit;" to="/payroll">payroll</Link>.
                    </Text>
                  </TextBlock>
                </Section>

                <Section>
                  <Heading text="Working with 1,500+ venues across the UK" />

                  <LogoWrapper>
                    <ClientLogoBar
                      logos={[
                        ClientLogos.MOLLIES,
                        ClientLogos.GUSTO,
                        ClientLogos.BREWDOG,
                        ClientLogos.PIZZA_PILGRIMS,
                        ClientLogos.WARNER_LEISURE_HOTELS,
                        ClientLogos.DISHOOM,
                        ClientLogos.CREAMS,
                        ClientLogos.NQ64,
                      ]}
                      withWrapper={false}
                    />
                  </LogoWrapper>
                </Section>

                <Section>
                  <Heading text="Our customers see an average ROI of 620%, thanks to..." />

                  <TextBlock>
                    <UL size="lg" uistyle="normal" iconUistyle="primary">
                      <TextListItem text="Intelligent staff scheduling" />
                      <TextListItem text="Powerful HR functionality" />
                      <TextListItem text="Effortless time & attendance monitoring" />
                      <TextListItem text="Advanced wage cost control" />
                      <TextListItem text="Tonnes of real-time integrations included" />
                    </UL>
                  </TextBlock>
                </Section>

                <Section>
                  <Heading text="Book a demo to learn exactly how Rotaready can help your business" />

                  <TextBlock>
                    <Blockquote
                      text="Not only has Rotaready has helped us save a significant amount on unnecessary wage spend, we've been able to better deploy over 16 hours per week across the business through smarter processes and also boost our revenue through better visibility of forecasts. As a result we've seen a 577% return on our investment."
                      attribution="Leo Day, Managing Director, Golden Lion Group"
                    />
                  </TextBlock>
                </Section>
              </BodyItem>

              <BodyItem>
                <ContactForm
                  title=""
                  commentsLabel="Any specific questions or requirements?"
                  submitLabel="Request demo"
                  showComments
                  showPhone
                  source="demo-brand"
                  marketoProgramName="FY25-P05-HOS-WEB-BOF-Demo-Rotaready-Brand-Demo-Rotaready"
                  label="rotaready.com/demo-brand"
                  initialFirstName={givenName}
                  initialLastName={familyName}
                  initialEmail={email}
                />
              </BodyItem>
            </BodyWrapper>
          </ContentWrapper>
        </ResponsiveContainer>
      </Body>
    );
  }
}

export default DemoPage;
